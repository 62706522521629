import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/stable";
import "regenerator-runtime/runtime";
import React, { useEffect } from "react";
import withApollo from "next-with-apollo";
import { ApolloProvider } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-boost";
import { ApolloClient } from "apollo-client";
import { ParallaxProvider } from "react-scroll-parallax";
import * as Sentry from "@sentry/node";
import { CaptureConsole } from "@sentry/integrations";

import { createHttpLink } from "apollo-link-http";
import fetch from "isomorphic-unfetch";
import Router, { useRouter } from "next/router";
import NProgress from "nprogress"; // nprogress module

import { LocaleProvider } from "../context/LocaleContext";
import {
  SustainabilityProvider,
  SustainabilityContext,
} from "../context/SustainabilityContext";
import Config from "../../config";
import { LastRouteContextProvider } from "../context/LastRouteContextProvider";
import * as gtag from "../util/gtag";

import { appWithTranslation } from "../locale/i18n";
import "../styles/spinner.scss";

import "../styles/global.scss";
import "slick-carousel/slick/slick.css";
import "../styles/slick/slick.scss";
import "../styles/pagination/pagination.scss";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

if (Config.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: Config.NEXT_PUBLIC_SENTRY_DSN,
    integrations: [
      new CaptureConsole({
        levels: ["error"],
      }),
    ],
  });
}

const link = createHttpLink({
  fetch, // Switches between unfetch & node-fetch for client & server.
  uri: Config.NEXT_PUBLIC_GRAPHQL_URL,
});

const MyApp = ({ Component, pageProps, apollo }) => {
  const { locales, regions, locale } = pageProps;
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const defaultGetLayout = (page) => <>{page}</>;
  const getLayout = Component.getLayout || defaultGetLayout;
  return (
    <ApolloProvider client={apollo}>
      <ParallaxProvider>
        <LastRouteContextProvider>
          <LocaleProvider loc={locale} locales={locales} regions={regions}>
            <SustainabilityProvider value={SustainabilityContext}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {getLayout(<Component {...pageProps} />)}
            </SustainabilityProvider>
          </LocaleProvider>
        </LastRouteContextProvider>
      </ParallaxProvider>
    </ApolloProvider>
  );
};

export default appWithTranslation(
  withApollo(({ initialState }) => {
    return new ApolloClient({
      link,
      cache: new InMemoryCache().restore(initialState || {}),
    });
  })(MyApp)
);
