import React, { useEffect, createContext, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import Config from '../../config';

export const SustainabilityContext = createContext({
  document: {},
  setDocument: () => null,
});

export const SustainabilityProvider = ({ document, children }) => {
  const [doc, setDocument] = useState(document);
  // store the preference
  useEffect(() => {
    const f = async () => {
      const headRes = await fetch(`${Config.NEXT_PUBLIC_DIRECTUS}sustainability?fields=*.*`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const res = await headRes.json();
      setDocument(res.data[0].report.data.full_url);
    };
    f();
  }, []);

  return (
    <SustainabilityContext.Provider value={{ doc }}>{children}</SustainabilityContext.Provider>
  );
};
