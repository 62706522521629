import React, { createContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';

const LastRouteContext = createContext([]);

export const LastRouteContextProvider = ({ children }) => {
  const [routes, setRoutes] = useState([]);
  const router = useRouter();
  useEffect(() => {
    setRoutes(x => [...x.slice(-1), router.asPath]);
  }, [router.asPath, setRoutes]);

  return <LastRouteContext.Provider value={routes}>{children}</LastRouteContext.Provider>;
};

export const useLastRoute = () => {
  const routes = React.useContext(LastRouteContext);
  return routes[0]?.replace(/^.{10}/, '');
};
